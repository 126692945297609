import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default function Navbar(props) {
  
  
  return (
    <>
    <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
    <div className="container-fluid">
      <Link className="navbar-brand" to="/">{props.Title}</Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link className={`nav-link text-${props.mode === 'white'?'dark':'white'} active`} aria-current="page" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link text-${props.mode === 'white'?'dark':'white'}`} to="/about">About</Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link text-${props.mode === 'white'?'dark':'white'}`} to="/contact">{props.Contact}</Link>
          </li>
        
        </ul>
        <div className="form-check form-switch">
          <input className="form-check-input" onClick={props.toogleMode} type="checkbox" id="flexSwitchCheckDefault"/>
        </div>
        <form className="d-flex">
          <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
          <button className="btn btn-outline-success" type="submit">Search</button>
        </form>
      </div>
    </div>
    </nav>
      {/* <hr/> */}
      </>
  )
}

Navbar.propTypes = {
    Title: PropTypes.string.isRequired,
    Contact: PropTypes.string.isRequired
}

Navbar.defaultProps ={
    Title : "rJCode01",
    Contact : "Contact Us"
}