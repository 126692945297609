import React, { useState } from 'react';
import './App.css';
import Alert from './components/Alert';
import About from './components/About';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
    const [mode, setMode] = useState('white')
    const [alert, setAlert] = useState(null)
  
    const showAlert = (message, type)=>{
        setAlert({
          msg: message,
          type: type
        });
        setTimeout(() => {
          setAlert(null)
        }, 1500);       
    }


    const toogleMode = ()=>{
      if(mode === 'white')
      {
        setMode('dark');
        document.body.style.backgroundColor = 'gray'
        showAlert("Dark mode has been enabled", "success");
        document.title = "TextConverter - Dark Mode"
      }
      else
      {
        setMode('white');
        document.body.style.backgroundColor = 'white'
        showAlert("Light mode has been enabled", "primary");
        document.title = "TextConverter - Light Mode"
      }
    }

  return (
   <>
  <Router>
  {/* <Navbar Title="TextConverter" Contact="Contact US"/> */}
{/* <Navbar Title={3}/> */}
  <Navbar Title="TextConverter" mode={mode} toogleMode={toogleMode} />
  <Alert alert={alert}/>
  <div className="container my-3">
  <Routes>
  <Route exact path="/about" element={<About/>} />
  <Route exact path="/" element={<TextForm heading="Enter the text to analyze below" mode={mode} showAlert={showAlert}/>} />
  </Routes>
  </div>
  </Router>
   </>
  );
}

export default App;
