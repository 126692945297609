import React, {useState} from 'react'

export default function TextForm(props) {

    const handleOnChange = (event)=>{
        // alert(text);
        setText(event.target.value)
    }

    const handleUpCase = ()=>{
        // alert("Click on Convert to UpperCase");
        let newText = text.toUpperCase();
        setText(newText);
        props.showAlert("Converted to Upper Case","primary")

    }

    const handlelwcase = ()=>{
        let newText = text.toLowerCase();
        setText(newText);
        props.showAlert("Converted to Lower Case","success")
        
    }

    const handleSentenceCase = ()=>{
        let t = text.toLowerCase();
        setText(text.charAt(0).toUpperCase()+ t.slice(1));
        props.showAlert("Converted to Sentence Case","danger")
    }

    const handleCapCase = ()=>{
        let arr = text.split(" ");
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        let CapText = arr.join(" ");
        
        setText(CapText);
        props.showAlert("Converted to Capitalized Case","warning")
    }
    const handleInVeRsECase = ()=>{
        const convertString = text => [...text].map(char => char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase()).join('');
        setText(convertString);
        props.showAlert("Converted to Inverse Case","success")

    }
    // const handleDownload = ()=>{
    //     let fileD = text;
    //     FileSystem(crea)
    //     // setText();
    // }
    const handleCtoClipboard = ()=>{
        navigator.clipboard.writeText(text)
        props.showAlert("Copy to Clipboard","success")
    }

    const handlePasteClipboard = ()=>{
        let t = navigator.clipboard.readText();
        console.log(t);
        // setText(t)
        props.showAlert("Paste to Clipboard","success")
    }
    
    const handleRemoveExSpace =()=>{
        let t = text.split(" ");
        let d = " ";
        for(let i = 0; i<t.length; i++)
        {
            d += t[i].trim();
            // d += " "
        }
        // t.forEach(element => {
        //      d += element.trim
        // });
        setText(d.trim());
            // let newText  = text.trim();
            // setText(newText);
            props.showAlert("Remove Extra Spaces", "danger")    
    }

    const handleClear = ()=>{
         setText("");
         props.showAlert("Clear ","dark")
        }

    const [text, setText] = useState("Enter the text");
  return (
    <>
    <div className={`container  text-${props.mode === 'white'?'dark':'white'}`}>
        <div className="mb-3">
            <h1>{props.heading}</h1>
            <textarea className={`form-control bg-${props.mode} text-${props.mode==='dark'?'white':'dark'}`}  value={text} onChange={handleOnChange} id="exampleFormControlTextarea1" rows="10"></textarea>
            <button disabled={text.length===0} className="btn btn-primary my-2" onClick={handleUpCase} >UPPER CASE</button>
            <button disabled={text.length===0} className="btn btn-success mx-2" onClick={handlelwcase} >lower case</button>
            <button disabled={text.length===0} className="btn btn-danger mx-2" onClick={handleSentenceCase} >Sentence case</button>
            <button disabled={text.length===0} className="btn btn-warning mx-2" onClick={handleCapCase} >Capitalized Case</button>
            <button disabled={text.length===0} className="btn btn-success my-2" onClick={handleInVeRsECase} >InVeRsE CaSe</button>
            {/* <button className="btn btn-success mx-2" onClick={handleDownload} >Download Text</button> */}
            <button disabled={text.length===0} className="btn btn-success mx-2" onClick={handleCtoClipboard} >Copy to Clipboard</button>
            <button disabled={text.length===0} className="btn btn-success mx-2" onClick={handlePasteClipboard} >Paste to Clipboard</button>
            <button disabled={text.length===0} className="btn btn-success mx-2" onClick={handleRemoveExSpace} >Remove Extra Space</button>
            <button disabled={text.length===0} className="btn btn-dark mx-2" onClick={handleClear} >Clear</button>
           
        </div> 
    </div>
    <div className={`container text-${props.mode === 'dark'?'white':'dark'}`}>
        <h2>Your Text Summary</h2>
        
        <p>{text.split(/\s+/).filter((element)=> {return element.length !== 0}).length} words and {text.length} characters</p>
        <p>{0.008 * text.length} Minutes read</p>
        <h2>Preview</h2>
        <p>{text.length>0?text:"Enter something in the textbox to preview."}</p>
    </div>
    </>
  )
}
